import React from "react";
import {GoogleReCaptchaProvider, GoogleReCaptcha} from "react-google-recaptcha-v3";
import Header from "../Header";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelope,
  faLock,
  faPhone,
  faArrowLeft,
} from "@fortawesome/free-solid-svg-icons";
import { validate, validations } from "indicative/validator";
import ProgressBar from "../ProgressBar";
import { connect } from "react-redux";
import { register } from "../../../store/Actions/authActions";
import { withTranslation } from "react-i18next";

import { compose } from "redux";
import cookie from "js-cookie";

const lang = [
  { lng: "English", id: 1 },
  { lng: "Français", id: 2 },
  { lng: "Nederlands", id: 3 },
];

class PersonalDetail extends React.Component {
  constructor() {
    super();
    this.state = {
      firstName: "",
      lastName: "",
      phone_number: "",
      email: "",
      password: "",
      termsConditions: false,
      readonly: true,
      passwordConfirmation: "",
      loading: false,
      success: false,
      phone: "",
      passwordShownI: false,
      passwordShownII: false,
      passwordConfirmationShown: false,
      check: false,
      lng: "",
      // companyName: "",
      // vat: "",
      error: [],
      errors: {
        email: null,
        password: null,
      },
      token: "",
      refreshReCaptcha: false,
    };
  }

  componentDidMount() {}
  componentWillUnmount() {
    this.setState({ readonly: true });
  }

  handleReCaptchaToken = (token) => {
    if (token) {
      this.setState({ token });
    }
  };

  handleCheck = (event) => {
    if (event.target.checked) {
      this.setState({
        termsConditions: true,
        error: [],
        check: true,
      });
    } else {
      this.setState({
        error: ["Terms and conditions are Declined"],
        check: false,
      });
    }
  };

  setTokenFunc = (getToken) => {
    this.setState({ token: getToken });
  };

  handleChange = (event) => {
    const { errors } = this.state;
    errors[event.target.name] = null;
    if (event.target.name === "passwordConfirmation") {
      this.setState({ errors: { email: "Passowrds doesnt Match" } });
      if (event.target.value === this.state.password) {
        this.setState({ errors: {} });
        this.setState({
          [event.target.name]: event.target.value,
        });
      }
    }
    if (event.target.name === "email") {
      const re =
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      const mail = re.test(event.target.value);
      this.setState({ errors: { email: "Please enter a valid email" } });
      if (mail) {
        this.setState({ errors: {} });
        this.setState({
          [event.target.name]: event.target.value,
        });
      }
    }
    if (event.target.name === "phone_number") {
      const re =
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      const mail = re.test(event.target.value);
      this.setState({ errors: { email: "Please enter a valid email" } });
      if (mail) {
        this.setState({
          errors: { email: "Please enter a valid phone number" },
        });
        event.target.value = "";
      } else {
        this.setState({ errors: {} });
        this.setState({
          [event.target.name]: event.target.value,
        });
      }
    }
    this.setState({
      [event.target.name]: event.target.value,
    });
  };
  togglePasswordI = () => {
    this.setState({ passwordShownI: !this.state.passwordShownI });
  };
  togglePasswordII = () => {
    this.setState({ passwordShownII: !this.state.passwordShownII });
  };
  handleSubmit = async (event) => {
    event.preventDefault();
    this.setState((prev) => {
      return {
        ...prev,
        loading: true,
      };
    });
    var postData = {
      first_name: this.state.firstName,
      last_name: this.state.lastName,
      phone_number: this.state.phone_number,
      email: this.state.email,
      password: this.state.password,
      password_confirmation: this.state.passwordConfirmation,
      recaptcha_token: this.state.token,
      // locale: this.state.lng,
      // company_name: this.state.companyName,
      // vat: this.state.vat,
      locale: cookie.get("i18next") == "fn" ? "fr" : cookie.get("i18next") == "du" ? "nl" : "en",
    };
    const rules = {
      email: "required|email",
      password: "required|min:8|confirmed",
    };
    validate(postData, rules)
      .then(
        this.props
          .dispatch(register(postData, this.props))
          .then((response) => {
            if (!response) {
              this.setState({
                errors: {
                  email: this.props?.msgs,
                },
                loading: false,
              });
            } else {
              this.setState((prev) => {
                return {
                  ...prev,
                  loading: false,
                  success: true,
                  errors: {},
                };
              });
              this.props.history.push("/use-wosh", [{prevPath: this.props.history.location.pathname}]);
            }
            // this.props.history.push("/use-wash");
          })
          .catch((error) => {
            // this.setState({ error: ["Error creating account"] });
            this.setState({
              errors: { email: this.props?.msgs },
              loading: false,
              refreshReCaptcha: !this.refreshReCaptcha,
            });
            this.props.history.push("/personal-details");
          })
      )
      .catch((errors) => {
        const formattedErrors = {};

        errors.forEach(
          (error) => (formattedErrors[error.field] = error.message)
        );
        this.setState({
          errors: formattedErrors,
        });
      });
  };

  render() {
    const { t } = this.props;
    return (
      <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_RECAPTCHA_KEY}>
        <div>
          <div className="top_right">
            <img src="/assets/imgs/bubble_3.png" />
          </div>
          <div className="bottom_right">
            <img src="/assets/imgs/bubble_2.png" className="img-fluid" />
          </div>
          <div className="middle_left">
            <img src="/assets/imgs/bubble.png" />
          </div>
          {/* <Header history={this.props.history} /> */}
          <div className="w-100" />
          <div className="container scroll_tab_content_new mb-5 z_index_one vh-90 d-flex flex-column justify-content-center">
            {/* <ProgressBar step={2} /> */}
            <div className="row">
              <div className="third col-md-3 col-lg-3 col-xs-12 d-flex align-items-end"></div>
              <div className="first col-md-6 col-lg-6 col-xs-12 m_top_2">
                <div className="w-75 m-auto login_main d-flex flex-column align-items-center">
                  <div className="d-flex flex-column main-title mb-5">
                    <h3 className="theme_color font_style">{t("Personal Details")}</h3>
                    {this.state.errors && this.state.errors.email && (
                      <p className="color-red">{t(this.state.errors.email)}</p>
                    )}
                    <div className="heading-line w-25" />
                  </div>
                  <div id="login_form" className="pb-5 w-100">
                    <div className="position-relative mb-4 form_input_style d-flex flex-row px-3 py-1 align-items-center" id="loginAutofill">
                      <label className="position-absolute px-3 white theme_bg lable_style">
                        {t("First Name")}
                      </label>
                      <img src="/assets/imgs/person.png" width={16} alt="" style={{maxHeight: '15px', objectFit: 'contain',}} />
                      <input
                        required
                        type="text"
                        className="form-control bg-transparent border-0 theme_color placeholderDark"
                        id="signupFirstName"
                        name="firstName"
                        aria-describedby="nameHelp"
                        placeholder={t("First Name")}
                        defaultValue={this.state.firstName}
                        onChange={this.handleChange}
                      />
                    </div>
                    <div className="position-relative mb-4 form_input_style d-flex flex-row px-3 py-1 align-items-center" id="loginAutofill">
                      <label className="position-absolute px-3 white theme_bg lable_style">
                        {t("Last Name")}
                      </label>
                      <img src="/assets/imgs/person.png" width={16} alt="" style={{maxHeight: '15px', objectFit: 'contain',}} />
                      <input
                        required
                        type="text"
                        className="form-control bg-transparent border-0 theme_color placeholderDark"
                        id="signupLastName"
                        name="lastName"
                        aria-describedby="lastNameHelp"
                        placeholder={t("Last Name")}
                        defaultValue={this.state.lastName}
                        onChange={this.handleChange}
                      />
                    </div>
                    <div className="position-relative mb-4 form_input_style d-flex flex-row px-3 py-1 align-items-center" id="loginAutofill">
                      <label className="position-absolute px-3 white theme_bg lable_style">
                        {t("Phone")}
                      </label>
                      {/* <FontAwesomeIcon icon={faPhone} /> */}
                      <img src="/assets/imgs/Vector.png" width={17} alt="" />
                      <input
                        type="tel"
                        className="form-control bg-transparent border-0 theme_color placeholderDark"
                        id="phone"
                        name="phone_number"
                        pattern={`[0-9]{3}-[0-9]{2}-[0-9]{3}`}
                        aria-describedby="phone"
                        placeholder="+32 47474747474"
                        readOnly={this.state.readonly}
                        onFocus={() => this.setState({ readonly: false })}
                        defaultValue={this.state.phone_number}
                        onChange={this.handleChange}
                      />
                    </div>
                    <div className="position-relative mb-4 form_input_style d-flex flex-row px-3 py-1 align-items-center" id="loginAutofill">
                      <label className="position-absolute px-3 white theme_bg lable_style">
                        {t("Email")}
                      </label>
                      {/* <FontAwesomeIcon icon={faEnvelope} /> */}
                      <img src="/assets/imgs/mail.png" width={18} alt="" />
                      <input
                        required
                        type="email"
                        className="form-control bg-transparent border-0 theme_color placeholderDark"
                        id="email"
                        name="email"
                        aria-describedby="emailHelp"
                        placeholder="youremail@gmail.com"
                        defaultValue={this.state.email}
                        onChange={this.handleChange}
                      />
                    </div>

                    <div className="position-relative mb-4 form_input_style d-flex flex-row px-3 py-1 align-items-center" id="loginAutofill">
                      <label className="position-absolute px-3 white theme_bg lable_style">
                        {t("Password")}
                      </label>
                      {/* <FontAwesomeIcon icon={faLock} /> */}
                      <img src="/assets/imgs/lock.png" width={16} alt="" />
                      <input
                        required
                        type={this.state.passwordShownI ? "text" : "password"}
                        className="form-control bg-transparent border-0 theme_color placeholderDark"
                        id="signupPassword"
                        name="password"
                        aria-describedby="passwordHelp"
                        placeholder={t("Password")}
                        defaultValue={this.state.password}
                        onChange={this.handleChange}
                      />
                      <div className="hide_show_btn">
                        <img
                          id="eye_img"
                          src="/assets/imgs/eye_pass_black.png"
                          width={20}
                          alt="Show/Hide Password"
                          onClick={this.togglePasswordI}
                          className="cursor_pointer"
                        />
                      </div>
                    </div>
                    <div className="position-relative mb-4 form_input_style d-flex flex-row px-3 py-1 align-items-center" id="loginAutofill">
                      <label className="position-absolute px-3 white theme_bg lable_style">
                        {t("Confirm password")}
                      </label>
                      {/* <FontAwesomeIcon icon={faLock} /> */}
                      <img src="/assets/imgs/lock.png" width={16} alt="" />
                      <input
                        required
                        type={this.state.passwordShownII ? "text" : "password"}
                        className="form-control bg-transparent border-0 theme_color placeholderDark"
                        id="signupConfirmPassword"
                        name="passwordConfirmation"
                        aria-describedby="confirmPasswordHelp"
                        placeholder={t("Confirm password")}
                        defaultValue={this.state.passwordConfirmation}
                        onChange={this.handleChange}
                      />
                      <div className="hide_show_btn">
                        <img
                          id="eye_img"
                          src="/assets/imgs/eye_pass_black.png"
                          width={20}
                          alt="Show/Hide Password"
                          onClick={this.togglePasswordII}
                          className="cursor_pointer"
                        />
                      </div>
                    </div>
                    <div className="mb-3 form-check">
                      <input
                        required
                        type="checkbox"
                        className="form-check-input"
                        name="terms"
                        id="termsConditions"
                        onChange={this.handleCheck}
                      />
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.wosh.be/legal/terms-conditions"
                        className="form-check-label check_lable text-decoration-underline"
                        style={{ textDecoration: "none", color: "#414882" }}
                        htmlFor="exampleCheck1"
                      >
                        {t("I have read and agree to the terms and conditions")}
                      </a>
                    </div>
                    <div className="mb-4">
                    
                      <GoogleReCaptcha
                        className="google-recaptcha-custom-class"
                        action={"verify"}
                        onVerify={this.setTokenFunc}
                        refreshReCaptcha={this.state.refreshReCaptcha}
                      />
                    </div>
                    {!this.state.loading && !this.state.success ? (
                      <button
                        onClick={this.handleSubmit}
                        type="submit"
                        className={`${
                          !this.state.check ||
                          this.state.email === "" ||
                          this.state.firstName === "" ||
                          this.state.lastName === "" ||
                          this.state.password === "" ||
                          this.state.passwordConfirmation === "" ||
                          this.state.phone_number === ""
                            ? "w-75 btn fill_btn mt-5 ms-auto me-auto d-flex justify-content-center disabled"
                            : "w-75 btn fill_btn mt-5 ms-auto me-auto d-flex justify-content-center"
                        }`}
                      >
                        {t("Continue")}
                      </button>
                    ) : this.state.loading && !this.state.success ? (
                      <button
                        onClick={this.handleSubmit}
                        type="submit"
                        className="w-75 btn fill_btn mt-5 ms-auto me-auto d-flex justify-content-center disabled"
                      >
                        {t("processing")}
                      </button>
                    ) : null}
                  </div>
                </div>
              </div>
              <div className="col-md-4 col-lg-4 col-sm-3 col-xs-12" />
            </div>
            <div className="footer_btn position-fixed mt-auto footer_btn d-flex flex-row align-items-center pt-3">
              <div className="mt-auto footer_btn d-flex flex-row align-items-center pt-3">
                <Link to="/sign-in" className="text-decoration-none small">
                  <FontAwesomeIcon icon={faArrowLeft} />{" "}
                  {t("Already have an account ?")}{" "}
                </Link>
                <Link to="/sign-in" className="ms-2 small">
                  {" "}
                  <strong className="font_style">{t("Sign-in")}</strong>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </GoogleReCaptchaProvider>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    msgs: state?.auth?.error,
  };
};

export default compose(
  withTranslation(),
  connect(mapStateToProps)
)(PersonalDetail);
